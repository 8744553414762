import { useCallback, useState } from 'react';
import {
  Button,
  LegacyStack,
  Text,
  Thumbnail,
  DropZone,
  Spinner,
  Box,
} from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import { useField } from 'formik';
import { onFileChange } from '@/util/upload-file';
import { useToaster } from '@/hooks';
import styled from 'styled-components';

const Dropzone = ({ allowMultiple, variableHeight, value, onDelete }) => {
  const [field, meta, helpers] = useField(value);
  const toast = useToaster();
  const [loading, setLoading] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      onFileChange(acceptedFiles[0], toast, setLoading, helpers.setValue);
    },
    [helpers.setValue, toast]
  );

  const handleRemoveFile = (event) => {
    event.stopPropagation();
    helpers.setValue(null);
    onDelete();
  };

  const fileUpload = !field.value && <DropZone.FileUpload />;
  const loadingState = loading && (
    <StyledBox>
      <Spinner size="small" />
    </StyledBox>
  );
  const uploadedFiles = field.value && (
    <div style={{ padding: '4px' }}>
      <LegacyStack vertical>
        <LegacyStack alignment="center">
          <LegacyStack>
            <Thumbnail
              size="small"
              alt={field.value.name}
              source={field.value.url}
            />
            <div>
              {field.value.name}{' '}
              <Text variant="bodySm" as="p">
                {field.value.size} bytes
              </Text>
            </div>
          </LegacyStack>
          <Button
            icon={DeleteMajor}
            onClick={handleRemoveFile}
            variant="plain"
          />
        </LegacyStack>
      </LegacyStack>
    </div>
  );

  return (
    <DropZone
      allowMultiple={allowMultiple}
      variableHeight={variableHeight}
      onDrop={handleDropZoneDrop}
    >
      {true && loadingState}
      {!loading && uploadedFiles}
      {!loading && fileUpload}
    </DropZone>
  );
};

export default Dropzone;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;
