import { Box, Text, Divider, Bleed } from '@shopify/polaris';

export const CardSection = ({ title, divider = true, children }) => {
  return (
    <Box>
      {divider ? (
        <Bleed marginInline="400">
          <Box paddingBlockStart="200">
            <Divider />
          </Box>
        </Bleed>
      ) : null}
      <Box>
        {title && (
          <Box paddingBlockStart="200">
            <Text as="p" variant="bodySm" fontWeight="medium" tone="subdued">
              {title.toUpperCase()}
            </Text>
          </Box>
        )}
        <Box paddingBlockStart="400" paddingBlockEnd="400">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
