import useStore from './store';

export const useSupport = () => {
  const { store } = useStore();

  const isChatEnabled = store?.orderCount >= 50;

  const startContactSupport = () => {
    if (isChatEnabled) {
      window.Atlas.chat.openWindow();
    } else {
      window.open('mailto:support@assortion.com');
    }
  };

  return {
    isChatEnabled,
    startContactSupport,
  };
};
