import { useQuery } from '@apollo/client';
import { FEATURES } from '@/query';

const useFeature = (name) => {
  const { data, loading, error } = useQuery(FEATURES);

  if (loading) return null;

  const features = data?.store?.features || {};

  return features[name];
};

export default useFeature;
