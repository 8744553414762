import { useState, useCallback } from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';
import List from './list';

const CollectionPicker = ({ value, setValue }) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(value || []);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleSave = useCallback(() => {
    if (selected.length > 0) {
      setValue(selected);
    }

    handleChange();
  }, [handleChange, setValue, selected]);

  const activator = (
    <Button onClick={handleChange}>
      {selected.length === 0
        ? 'Select collections'
        : `${selected.length} collection${
            selected.length > 1 ? 's' : ''
          } selected`}
    </Button>
  );

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Select collections"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          disabled: selected.length === 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <List selected={selected} setSelected={setSelected} />
      </Modal>
    </div>
  );
};

export default CollectionPicker;
